import './App.css';

import GameList from "./GameList";
import {db, handSize, useFirestore, useFirestoreQuery} from "./Firebase";

import { ThemeProvider } from '@material-ui/styles';
import theme from './theme.js';

import { useState } from "react";
import SentenceBuilder from "./SentenceBuilder";
import SentenceJudging from "./SentenceJudging";

function App() {

    const { data, status, error } = useFirestoreQuery(
        db.collection("games").doc('1')
    );

    const players = useFirestoreQuery(
        db.collection("players")
    );

    const fullHandForSelf = () => {
        if (players.data === undefined) return [];
        console.log(`players data: ${players.data}`);
        let playerIndex = players.data.findIndex((remotePlayer) => player.id === remotePlayer.id);
        if (playerIndex < 0) return [];
        console.log(`playerIndex: ${playerIndex}`);
        let playerHand = players.data[playerIndex].hand;
        console.log(`playerHand: ${playerHand}`);

        if (playerHand === undefined || playerHand.length < handSize) return [];
        return playerHand;
    }

    const [player, setPlayer] = useState();

    if (status === "loading" || data === undefined) {
        return "Loading...";
    }
    if (status === "error") {
        return `Error: ${error.message}`;
    }

  return (
      <div className="App" style={{ height:'100%' }}>
          <ThemeProvider theme={theme}>
        {(data.state === 'lobby' || player === undefined) &&
            <GameList player={player} setPlayer={setPlayer} game={data}/>
        }
        {(data.state === 'sentenceBuilding' && player !== undefined && fullHandForSelf().length > 0) &&
            <div style={{ height:'100%' }}>Player {player.id}
            <SentenceBuilder player={player} hand={fullHandForSelf()}/>
            </div>
        }
        {(data.state === 'sentenceJudging' && player !== undefined) &&
        <div style={{ height:'100%' }}>Player {player.id}
            <SentenceJudging player={player}/>
        </div>
        }
        {(data.state === 'postRound' && player !== undefined) &&
        <div style={{ height:'100%' }}>Player {player.id}
            <GameList player={player} game={data} postRound={true} cardsRemaining={(data.deck !== undefined ? data.deck.length : 0)}/>
        </div>
        }
          </ThemeProvider>
    </div>
  );
}

export default App;


import React, {useEffect, useState} from 'react';
import Paper from '@material-ui/core/Paper';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getCards } from './cardsRaw';


import { useFirestoreQuery, db, startGame, startNextRound, handSize } from "./Firebase";


const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));


function GameList({ player, setPlayer, postRound, cardsRemaining = getCards().length }) {

    const classes = useStyles();

      // Subscribe to Firestore document
  const { data, status, error } = useFirestoreQuery(
    db.collection("players")
  );

    const [open, setOpen] = useState(false);
    const [formName, setFormName] = useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const joinGame = () => {
        let playerObject = {
            id: formName,
            isOwner: true
        };

        let existingPlayer = data.filter((player) => { return player.id === formName})[0];
        if (existingPlayer !== undefined && existingPlayer !== null) {
            console.log('Setting player existing player ' + formName);
            setPlayer(playerObject);
            handleClose();
        } else {
            console.log('Creating new player ' + formName);

            db.collection('players').doc(formName).set(playerObject).then( () => { setPlayer(playerObject); });
            handleClose()
        }
    };

    if (status === "loading"){
        return "Loading...";
    }
    if (status === "error"){
        return `Error: ${error.message}`;
    }

    const cardsNeeded = () => {
        return data.reduce((accumulator, nextValue) => {
            let currentHandSize = 0;
            if(nextValue.hand !== undefined) currentHandSize = nextValue.hand.length;
            return accumulator + handSize - currentHandSize;
        }, 0);
    }

    const displayString = (playerToShow) => {
        let string = playerToShow.id;
      if(player !== undefined && playerToShow.id === player.id) string = playerToShow.id + ' - You';

      string = string + '  -  ' + (playerToShow.points || 0) + ' points';
      return string;
    };

    return (
        <div className={classes.root}>
            <Paper minWidth={240} className={classes.root}>
                {data.map((player) => {
                    return ( <ListItem>{displayString(player)}</ListItem>)
                })}
                <div>
                    Cards remaining in deck: {cardsRemaining} <br/>
                    Cards needed for a new round: {cardsNeeded()} <br/>
                </div>
                {(player === undefined || player === null) &&
                <Button variant="contained" onClick={handleClickOpen}>Join Game</Button>
                }

                {(player !== undefined && (postRound === false || postRound === undefined)) &&
                <Button variant="contained" onClick={startGame} color="primary" disabled={cardsNeeded() > cardsRemaining}>
                    Start Game
                </Button>
                }
                {(player !== undefined && postRound === true && cardsRemaining) &&
                    <Button variant="contained" onClick={startNextRound} color="primary" disabled={cardsNeeded() > cardsRemaining}>
                    Another Round!
                </Button>
                }
            </Paper>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Join Game</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter your name:
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        fullWidth
                        onChange={(event) => {setFormName(event.target.value)} }
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button disabled={(formName == null || formName.length == 0)} onClick={joinGame} color="primary">
                        Join
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default GameList;
import {Button, Paper, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core";
import {startGame} from "./Firebase";
import React from "react";

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: theme.spacing(1),
        padding: theme.spacing(1)
    },
    sentence: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
        fontWeight: 500
    },
    pos: {
        marginBottom: 12,
    },
    points: {
        fontWeight: 400,
        fontSize: 14
    }
}));

const pointsString = (points, bonus) => {
    if (bonus) {
        return points - 5 + '+5 points (all card bonus)';
    } else {
        return points + ' points';
    }
}

function SentencePreview({sentence, points, bonus, handleSubmit}) {

    const classes = useStyles();
    console.log(sentence);
    return (
        <Paper variant="outlined" className={classes.paper}>
            <Typography className={classes.title}>Current Sentence:</Typography>
            <Typography className={classes.sentence}>{(sentence.length > 0 ? sentence: '-')}</Typography>
            <Typography className={classes.title}>Value:</Typography>
            <Typography className={classes.points}>{pointsString(points, bonus)}</Typography>
            <br/>
            <Button variant="contained" color="primary" onClick={handleSubmit} disabled={(sentence.length === 0)}>Submit</Button>
        </Paper>

    );

}

export default SentencePreview;
import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom";
import styled from "@emotion/styled";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Card } from '@material-ui/core';
import SentenceJudging from "./SentenceJudging";
import { v4 as uuidv4 } from 'uuid';
import {makeStyles} from "@material-ui/core/styles";

const initial = Array.from({ length: 10 }, (v, k) => k).map(k => {
    const custom = {
        id: `id-${k}`,
        content: `Quote ${k}`
    };

    return custom;
});

const grid = 8;
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const copy = (source, destination, droppableSource, droppableDestination) => {
    console.log('==> dest', destination);

    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const item = sourceClone[droppableSource.index];

    destClone.splice(droppableDestination.index, 0, { ...item, id: 'punctuation' + uuidv4() });
    console.log('new dest', destClone);
    return {sentence: destClone};
};

const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

const WordItem = styled.div`
  //border: 1px solid grey;
  margin-bottom: ${grid}px;
  //background-color: lightblue;
  padding: ${grid}px;
`;

// const Clone = styled(Item)`
//     + div {
//         display: none !important;
//     }
// `;

const useStyles = makeStyles((theme) => ({
  card: {
      margin: '4px',
      padding: '4px',
      backgroundColor: '#EFEFEF'
    }
}));

function Word({ word, index }) {

    const classes = useStyles();
    return (
        <Draggable draggableId={word.id} index={index} isDragDisabled={!word.id.startsWith('punctuation')}>
            {(provided, snapshot) => (
                <Card
                    className={classes.card}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    {word.word}
                </Card>
            )}
        </Draggable>
    );
}

function CloneableWord({ word, index }) {
    const classes = useStyles();

    return (
        <Draggable draggableId={word.id} index={index} isDragDisabled={!word.id.startsWith('punctuation')}>
            {(provided, snapshot) => (
                <React.Fragment>
                    <Card
                        className={classes.card}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        {word.word}
                    </Card>
                    {snapshot.isDragging && (
                        <Card className={classes.card}>{word.word}</Card>
                    )}
                </React.Fragment>
            )}
        </Draggable>
    );
}

const Sentence = (words) =>  {
    return words.map((word, index) => (
        <Word word={word} index={index} key={word.id}>
            {word}
        </Word>
    ));
};

const PunctuationPane = (words) =>  {
    return words.map((word, index) => (
        <CloneableWord word={word} index={index} key={word.id}>
            {word}
        </CloneableWord>
    ));
};

const buildUniqueItems = (words, prefix = '') => {
    return words.map((word) => {
       return {
           word: word,
           id: prefix + uuidv4()
       }
    });
}

const getCanvasStyle = (isDraggingOver) => {
    return {
        flexDirection: 'horizontal',
        display: 'flex',
        justifyContent: 'center'
    }
}

const splitSentence = (sentence) => {
    const array = sentence.split(' ');
    // const result = array.reduce((r, a) => r.concat(a, ' '), []);
    return array;
}

const determineAffinity = (punctuation, quotesOpened) => {
    //["!", ".", ",", "\"", "?", ";", ":", "—"]

    if (["!", ".", "?"].indexOf(punctuation.word) > -1) {
        return 'sentenceEnding';
    }
    if ([",", ";", ":"].indexOf(punctuation.word) > -1) {
        return 'leftAffinity';
    }
    if (["\""].indexOf(punctuation.word) > -1) {
        return quotesOpened ? 'leftAffinity' : 'rightAffinity';
    }
    if (["—", "..."].indexOf(punctuation.word) > -1) {
        return 'centerAffinity';
    }
    return 'leftAffinity';
}

const isPunctuation = (word) => {
    return word.id.startsWith('punctuation');
}

const currentSentence = (sentence) => {
    // add single space between internal word pairs
    // add a space after left-affinity punctuation
    // add two spaces and capitalize the next word for sentence-ending punctuation
    // add no spaces for center-affinity punctuation
    // add a space before for right-affinity punctuation

    let sentenceCopy = Array.from(sentence);
    let quotesOpened = false;
    let spacedSentence = sentenceCopy.reduce((accumulator, word, index) => {

        if (isPunctuation(word)) {
            let affinity = determineAffinity(word, quotesOpened);
            if (affinity === 'leftAffinity') {
                if(word.word === "\"") {
                    quotesOpened = !quotesOpened;
                }
                return accumulator + word.word + ' ';
            }
            if (affinity === 'rightAffinity') {
                if(word.word === "\"") {
                    quotesOpened = !quotesOpened;
                }
                return accumulator + ' ' + word.word;
            }
            if (affinity === 'sentenceEnding') {
                if (sentenceCopy.length > index + 1) {
                    let nextWord = sentenceCopy[index + 1].word;
                    nextWord = `${nextWord[0].toUpperCase()}${nextWord.slice(1)}`;
                    sentenceCopy[index + 1] = { ...sentenceCopy[index + 1], word: nextWord };
                    if (isPunctuation(sentenceCopy[index + 1])) {
                        // consecutive sentence enders or left affinity punc, last one will handle spaces
                        return accumulator + word.word;
                    }
                }
                return accumulator + word.word + '  ';
            }
            if (affinity === 'centerAffinity') {
                return accumulator + word.word;
            }
        }

        // non-punctuation
        if (index === 0) {
            return accumulator + word.word;
        }
        // previous word was also a non-punctuation; add a single space.
        if (!isPunctuation(sentenceCopy[index -1])) {
            return accumulator + ' ' + word.word;
        }
        // previous word was punctuation and it handled any needed spaces
        return accumulator + word.word;

    }, '');
    return spacedSentence;
}

const punctuation = ["!", ".", ",", "\"", "?", ";", ":", "—", "..."];

function Punctuator({ sentence, handlePunctuationUpdate}) {
    const [state, setState] = useState({
        sentence : buildUniqueItems(splitSentence(sentence)),
        punctuation: buildUniqueItems(punctuation, 'punctuation')
    });

    console.log(`punct state: ${state.sentence}`);
    function onDragEnd(result) {
        const { source, destination } = result;

        if ((destination === null && source.droppableId === 'sentence') ||
            (destination !== null && destination.droppableId === 'punctuation' && source.droppableId === 'sentence')) {
            console.log('deleting');
            const sentenceClone = Array.from(state.sentence);
            sentenceClone.splice(source.index, 1);

            setState({
                sentence: sentenceClone,
                punctuation: state.punctuation
            });
            return;
        } else if (destination === null) {
            return;
        }

        if (source.droppableId === destination.droppableId) {

            // dragging within punctuation area; do nothing
            if (source.droppableId === 'punctuation') {
                console.log('dragging within punctuation');
                return;
            }

            // dragging within sentence
            const items = reorder(
                state.sentence,
                source.index,
                destination.index
            );

            setState({
                sentence: items,
                punctuation: state.punctuation});
        } else {
            let result;
            if (destination.droppableId === 'sentence') {
                console.log('copying');
                result = copy(
                    state.punctuation,
                    state.sentence,
                    source,
                    destination
                );
            } else {
                console.log('deleting');
                result = move(
                    state.sentence,
                    state.punctuation,
                    source,
                    destination
                );
            }

            setState({
                sentence: result.sentence,
                punctuation: state.punctuation
            });
        }
    }

    useEffect(() => {
        let updatedSentence = currentSentence(state.sentence);
        console.log(`spaced sentence: ${updatedSentence}`);
        handlePunctuationUpdate(updatedSentence);
    }, [state]);

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="sentence" direction="horizontal">
                {(provided, snapshot) => (
                    <div ref={provided.innerRef}
                         style={getCanvasStyle(snapshot.isDraggingOver)}
                         {...provided.droppableProps}>
                        {Sentence(state.sentence)}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
            <Droppable droppableId="punctuation" direction="horizontal" isDropDisabled={true}>
                {(provided, snapshot) => (
                    <div ref={provided.innerRef}
                         style={getCanvasStyle(snapshot.isDraggingOver)}
                         {...provided.droppableProps}>
                        {PunctuationPane(state.punctuation)}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}

export default Punctuator;

import React, {useEffect, useState} from "react";
import {Avatar, Card, Chip, List, ListItem, ListItemAvatar, ListItemText, TextField} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { blue, green, yellow, orange, deepOrange } from '@material-ui/core/colors';
import StarsIcon from '@material-ui/icons/Stars';
import {Star} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    root: {
        '&$selected': {
            backgroundColor: '#666666',
            color: '#FFFFFF',
            '&:hover': {
                backgroundColor: '#888888',
            }
        }
    },
    selected: {},
    card: {
    },
    5: {
        fontSize: '1rem',
        width: theme.spacing(2.4),
        height: theme.spacing(2.4),
        marginRight: theme.spacing(1),
        backgroundColor: blue[100],
        color: theme.palette.getContrastText(blue[100]),
    },
    4: {
        fontSize: '1rem',
        width: theme.spacing(2.4),
        height: theme.spacing(2.4),
        marginRight: theme.spacing(1),
        backgroundColor: green[100],
        color: theme.palette.getContrastText(green[100]),
    },
    3: {
        fontSize: '1rem',
        width: theme.spacing(2.4),
        height: theme.spacing(2.4),
        marginRight: theme.spacing(1),
        backgroundColor: yellow[100],
        color: theme.palette.getContrastText(yellow[100]),
    },
    2: {
        fontSize: '1rem',
        width: theme.spacing(2.4),
        height: theme.spacing(2.4),
        marginRight: theme.spacing(1),
        backgroundColor: orange[100],
        color: theme.palette.getContrastText(orange[100]),
    },
    1: {
        fontSize: '1rem',
        width: theme.spacing(2.4),
        height: theme.spacing(2.4),
        marginRight: theme.spacing(1),
        backgroundColor: deepOrange[100],
        color: theme.palette.getContrastText(deepOrange[100]),
    },
    itemText: {
        lineHeight: '1.0'
    },
    star: {
        height: '65px',
        width: '65px',
        padding: '10px'
    },
    wildCard: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        margin: '20px',
        minHeight: '110px',
        minWidth: '193px'
    },
    wildTextField: {
        fontSize: '14px'
    }
}));


function HandCard({ card, handleSelection, isDragging, handleWildUpdate }) {

    const classes = useStyles();

    const handleClick = (index) => {
        if (handleSelection !== undefined) {
            handleSelection(card.id, index);
        }
    }

    const updateWildValue = (newValue) => {
        if (handleWildUpdate !== undefined && newValue.length <= 30) {
            handleWildUpdate(card.id, newValue);
        }
    }

    return (
        <Card style={{boxShadow: isDragging ? '2px 4px 6px 2px rgba(0,0,0,0.5)' : '', minHeight: '166px', minWidth: '130px'}}>
            {(card.words[4].word === 'Wild Card') ?
                <div className={classes.wildCard}>
                <StarsIcon
                    className={classes.star}
                    fontSize='large'
                    color='primary'
                >
                </StarsIcon>
                    {(handleWildUpdate !== undefined) ? (
                    <TextField
                        className={classes.wildTextField}
                        inputProps={{style: { textAlign: 'center' }}}
                        labelProps={{style: { textAlign: 'center' }}}
                        hintStyle={{ textAlign: 'center', width: '100%' }}
                        floatingLabelStyle={{ textAlign: 'center', width: '100%', transformOrigin: 'center top 0px' }}
                        name="wild"
                        variant="outlined"
                        size="small"
                        type="text"
                        value={card.words[0].word}
                        onMouseDown={e => e.stopPropagation()}
                        onChange={(e) => updateWildValue(e.target.value)}
                    />
                    ) : (
                        <Chip label='WILD CARD' variant='outlined' color='primary'/>
                    )}
                </div>
                :


                <List dense>
                    {card.words.map((word, index) => {
                        return (
                            <ListItem button={(handleSelection !== undefined)}
                                      onClick={() => handleClick(index)}
                                      selected={(handleSelection !== undefined && index === card.selectedIndex)}
                                      classes={{selected: classes.selected, root: classes.root}}>
                                <Avatar className={classes[word.points]}>{word.points}</Avatar>
                                <ListItemText primary={word.word} className={classes.itemText}/>
                            </ListItem>);
                    })}
                </List>
            }
        </Card>
    );
}

export default HandCard;
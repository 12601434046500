
const cardsRaw = [
    ["pain","1","painfully",2,"painful",1,"pained",2,"pains",1  ],
    ["widening","1","widens",1,"widest",1,"wider",1,"wide",1  ],
    ["Europe","1","South America",1,"Asia",1,"Australia",1,"North America ",1  ],
    ["Jumpin' Jack","1","John Adams",1,"Johnny Carson",1,"Wild \"J\" Name",1,"Jim Halpert",1  ],
    ["the","1","to",1,"a(n)",1,"and",1,"of",1  ],
    ["","0","Wild Card",0,"Wild Card",0,"Wild Card",0,"Wild Card",0  ],
    ["as a(n)","1","as in",1,"as of",1,"as the",1,"as",1  ],
    ["squashing","1","squashy",2,"squash",1,"squashes",1,"squashed",1  ],
    ["","0","Wild Card",0,"Wild Card",0,"Wild Card",0,"Wild Card",0  ],
    ["flop","1","flops",1,"flopped",1,"flopping",1,"floppy",1  ],
    ["odd","1","odds",1,"oddly",1,"odder",4,"oddest",1  ],
    ["Lash LaRue","1","Wild \"L\" Name",1,"Larry Lounge Lizard",1,"Lonely Loretta",1,"Laurence Olivier",1  ],
    ["mouths","1","mouthy",1,"mouthing off",1,"mouthwash",1,"mouth",1  ],
    ["pointy","1","point",1,"points",1,"pointed",2,"pointing",1  ],
    ["game","1","games",1,"gamed",1,"gaming",1,"gamer",2  ],
    ["puffs","1","puffed",1,"puffing",1,"puffy",2,"puff",1  ],
    ["over the","1","next",1,"off of",1,"on the",1,"or the",1  ],
    ["kicks","1","kicked",1,"kicking",1,"kicker",2,"kick",1  ],
    ["cared","1","caring",1,"caregiver",2,"care",1,"cares",1  ],
    ["batting","1","batter",1,"bat",1,"bats",1,"batted",1  ],
    ["shorting","1","shorten",1,"short",1,"shorts",1,"shorted",1  ],
    ["icing","1","icy",1,"ice",1,"ices",1,"iced",1  ],
    ["","0","Wild Card",0,"Wild Card",0,"Wild Card",0,"Wild Card",0  ],
    ["sweeten","1","sweet",1,"sweets",1,"sweeter",1,"sweetest",1  ],
    ["Philadelphia","1","Chicago",1,"Phoenix",1,"Houston",1,"St. Louis",1  ],
    ["around the","1","away from",1,"because of",1,"another of",1,"before the",1  ],
    ["both of","1","must have",1,"may be",1,"middle of",1,"more of",1  ],
    ["traveling","1","traveler",1,"travel",1,"travels",1,"traveled",1  ],
    ["laughed","1","laughing",1,"laugher",2,"laugh",1,"laughs",1  ],
    ["the pond","1","the creek",1,"the ocean",1,"the lake",1,"the river",1  ],
    ["smarty","1","smart",1,"smarts",1,"smarted",1,"smarting",2  ],
    ["boring","1","most boring",1,"bore",1,"bores",1,"bored",1  ],
    ["readers","1","reader",1,"reading",1,"reads",1,"read",1  ],
    ["dirtier","1","dirtiest",1,"dirty",1,"dirt",1,"dirt pile",1  ],
    ["rain","1","rains",1,"rained",1,"raining",1,"rainy",1  ],
    ["lady","1","ladies",1,"ladies only",1,"ladies' night",1,"ladylike",1  ],
    ["lick","1","licks",1,"licked",1,"licker",2,"licking",1  ],
    ["yuckier","1","yuck",1,"yucks",2,"yucky",1,"yuckiest",1  ],
    ["Wild \"L\" Name","1","Lucky Lou",1,"Sir Lancelot",1,"Leonardo DaVinci",1,"Laura Ingalls Wilder",1  ],
    ["","0","Wild Card",0,"Wild Card",0,"Wild Card",0,"Wild Card",0  ],
    ["","0","Wild Card",0,"Wild Card",0,"Wild Card",0,"Wild Card",0  ],
    ["the boys locker room","1","the gym",1,"the nurse's office",1,"the girls locker room",1,"study hall",1  ],
    ["black","1","blacks",1,"blacken",1,"blackened",1,"blackening",1  ],
    ["and","1","and as",1,"and in",1,"and the",1,"and a(n)",1  ],
    ["feet","1","footsy",1,"foot",1,"footed",2,"footing",4  ],
    ["pits","1","pitted",1,"pitting",2,"tar pit",1,"pit",1  ],
    ["Fast Freddy","1","Francis Scott Key",1,"Frank Sinatra",1,"F. Scott Fitzgerald",1,"Wild \"F\" Name",1  ],
    ["matcher","4","match",1,"matches",1,"matched",1,"matching",1  ],
    ["quickly","1","quicker",1,"quickest",1,"quicken",1,"quick",1  ],
    ["blondish","2","blond",1,"blonds",1,"blonder",1,"blondest",1  ],
    ["","0","Wild Card",0,"Wild Card",0,"Wild Card",0,"Wild Card",0  ],
    ["weakening","1","weak",1,"weaker",1,"weakest",1,"weakened",1  ],
    ["bulls","1","bulled",2,"bullying",2,"bully",1,"bull",1  ],
    ["class","1","classes",1,"classed",1,"classless",2,"classy",1  ],
    ["space","1","spaces",1,"spaced",1,"spacing",1,"spacey",1  ],
    ["of","1","the",1,"to",1,"a(n)",1,"and",1  ],
    ["George Mason","1","Ghandi",1,"Wild \"G\" Name",1,"George Washington",1,"Gorgeous Gina",1  ],
    ["heading","1`","heady",1,"head",1,"heads",1,"headed",1  ],
    ["powder","1","powders",1,"powdered",1,"powdering",4,"powdery",1  ],
    ["punches","1","punched",1,"punching",1,"punchy",4,"punch",1  ],
    ["the lawyer's office","1","the police station",1,"the prison",1,"the court",1,"the jail",1  ],
    ["a(n)","1","and",1,"of",1,"the",1,"to",1  ],
    ["shockingly","1","shock",1,"shocks",1,"shocked",1,"shocking",1  ],
    ["potted","1","potting",1,"potter",1,"pot",1,"pots",1  ],
    ["smoke","1","smokes",1,"smoked",1,"smoky",1,"smokiest",1  ],
    ["dogged","1","dogging",1,"doggedly",4,"dog",1,"dogs",1  ],
    ["must have","1","may be",1,"middle of",1,"more of",1,"both of",1  ],
    ["patchy","1","patch",1,"patches",1,"patched",1,"patching",1  ],
    ["funnier","2","funniest",1,"fun",1,"most fun",1,"funny",1  ],
    ["Saintly Susan","1","Seattle Slew",1,"Sarah Barton",1,"Wild \"S\" Name",1,"Sir Edmund Hillary",1  ],
    ["trip","1","trips",1,"tripper",1,"tripping",1,"tripped",1  ],
    ["farther","1","far",1,"furthest",1,"farthest",1,"further",1  ],
    ["","0","Wild Card",0,"Wild Card",0,"Wild Card",0,"Wild Card",0  ],
    ["early in","1","either of",1,"down from ",1,"each of",1,"during the",1  ],
    ["in","1","inside of",1,"instead of",1,"into the",1,"how to",1  ],
    ["of","1","the",1,"to",1,"a(n)",1,"and",1  ],
    ["bombs","1","bombed",1,"bombing",2,"bomber",1,"bomb",1  ],
    ["real","1","really",1,"reality",1,"realistic",1,"realistically",4  ],
    ["more of","1","both of",1,"must have",1,"may be",1,"middle of",1  ],
    ["as in","1","as of",1,"as the",1,"as",1,"as a(n)",1  ],
    ["hippy","2","hip",1,"hips",1,"hipper",2,"hippest",2  ],
    ["name","1","names",1,"named",1,"naming",1,"namely",1  ],
    ["top","1","tops",1,"topped",1,"topping",1,"topper",1  ],
    ["petting zoo","1","petted",1,"petting",1,"pet",1,"pets",1  ],
    ["dished","1","dishing",1,"dishwasher",2,"dish",1,"dishes",1  ],
    ["poisoning","1","poisonings",2,"poison",1,"poisons",1,"poisoned",1  ],
    ["nursery","2","nurse",1,"nurses",1,"nursed",1,"nursing",1  ],
    ["assumption","2","assume",1,"assumes",1,"assumed",1,"assuming",1  ],
    ["friends","1","friend",1,"friendliest",1,"friendlier",1,"friendly",1  ],
    ["until it","1","until the",1,"upon the",1,"what the",1,"what if",1  ],
    ["fudgiest","2","fudge",1,"fudges",1,"fudged",2,"fudging",1  ],
    ["teller","1","tell",1,"tells",1,"told",1,"telling",1  ],
    ["Frederick Douglas","1","Wild \"F\" Name",1,"Fred Rogers",1,"Franklin D. Roosevelt",1,"Father Matt",1  ],
    ["theirs","1","ours",1,"her",1,"hers",1,"his",1  ],
    ["except for","1","far from",1,"for",1,"from",1,"enough of",1  ],
    ["a(n)","1","and",1,"of",1,"the",1,"to",1  ],
    ["as","1","in",1,"with",1,"on",1,"that",1  ],
    ["running","1","runny",2,"run",1,"runs",1,"ran",1  ],
    ["Mighty Matthew","1","Wild \"M\" Name",1,"Moms Mabley",1,"Marvelous Martin",1,"Marie Antoinette",1  ],
    ["showing","1","showed",1,"shows",1,"show",1,"shown",2  ],
    ["springing","1","springy",2,"spring",1,"springs",1,"sprang",1  ],
    ["out","1","outs",1,"outing",1,"outside",1,"outer",1  ],
    ["Wild \"T\" Name","1","Ted Knight",1,"Tiny Tim",1,"Terry Thomas",1,"Thomas Jefferson",1  ],
    ["colds","1","colder",1,"coldest",1,"coldly",2,"cold",1  ],
    ["Mario Andretti","1","Wild \"M\" Name",1,"Marilyn Monroe",1,"Rachel Maddow",2,"Marlon Brando",1  ],
    ["behind the","1","below the",1,"better than",1,"between the",1,"bottom of",1  ],
    ["blows","1","blew",1,"blowing",1,"blower",1,"blow",1  ],
    ["almost the","1","also the",1,"already the",1,"about the",1,"above the",1  ],
    ["for","1","from",1,"far from",1,"except for",1,"enough of",1  ],
    ["this","1","or",1,"but",1,"what",1,"by",1  ],
    ["glassiest","1","glass",1,"glasses",1,"glassy",4,"glassier",1  ],
    ["cheaper","1","cheapest",1,"cheapen",1,"cheapening",2,"cheap",1  ],
    ["will be","1","am",1,"is",1,"are",1,"was",1  ],
    ["lingual","1","linguistics",1,"multi-lingual",2,"language",1,"languages",1  ],
    ["yours","1","theirs",1,"my",1,"mine",1,"your",1  ],
    ["thinks","1","thought",1,"thinking",1,"thinker",1,"think",1  ],
    ["punts","1","punted",1,"punting",1,"punter",2,"punt",1  ],
    ["outside","1","outer",1,"out",1,"outs",1,"outing",1  ],
    ["dumps","1","dumped",1,"dumping",1,"dumpy",2,"dump",1  ],
    ["either of","1","down from",1,"each of",1,"durign the",1,"early in",1  ],
    ["and","1","of",1,"the",1,"to",1,"a(n)",1  ],
    ["","0","Wild Card",0,"Wild Card",0,"Wild Card",0,"Wild Card",0  ],
    ["and","1","of",1,"the",1,"to",1,"a(n)",1  ],
    ["nerves","1","nerved",2,"nerving",2,"nervous",1,"nerve",1  ],
    ["shapes","1","shaped",1,"shaping",1,"shapely",4,"shape",1  ],
    ["above the","1","almost the",1,"also the",1,"already the",1,"about the ",1  ],
    ["and","1","of",1,"the",1,"to",1,"a(n)",1  ],
    ["Jesse James","1","John Wayne",1,"Wild \"J\" Name",1,"James Dean",1,"Joe DiMaggio",1  ],
    ["","0","Wild Card",0,"Wild Card",0,"Wild Card",0,"Wild Card",0  ],
    ["Queen Elizabeth II","1","Edgar Allen Poe",1,"Wild \"E\" Name",1,"E.E. Cummings",1,"The Easter Bunny",1  ],
    ["fatso","1","fat",1,"fatter",1,"fattest",1,"fatty",1  ],
    ["spinner","1","spin",1,"spins",1,"spun",1,"spinning",4  ],
    ["inside of","1","instead of",1,"into the",1,"how to",1,"in",1  ],
    ["","0","Wild Card",0,"Wild Card",0,"Wild Card",0,"Wild Card",0  ],
    ["them","1","it",1,"I",1,"me",1,"you",1  ],
    ["milks","1","milked",1,"milking",1,"milky",1,"millk",1  ],
    ["far from","1","for",1,"from",1,"enough of",1,"except for",1  ],
    ["Northern Calloway","1","Nate Nelson",1,"Noble Ned",1,"Wild \"N\" Name",1,"Nigel Hawthorne",1  ],
    ["writing","1","written",1,"write",1,"writes",1,"wrote",1  ],
    ["and","1","and as",1,"and in",1,"and the",1,"and a(n)",1  ],
    ["nears","1","neared",1,"nearing",1,"nearly",1,"near",1  ],
    ["The 60's","1","The 70's",1,"The 80's",1,"The 90's",1,"The 50's",1  ],
    ["Odysseus","1","Ornery Ozzie",1,"Wild \"O\" Name",1,"Ophelia",1,"Orion",1  ],
    ["famously","1","fame",1,"infamous",1,"famed",1,"famous",1  ],
    ["walking","1","walker",2,"walk",1,"walk",1,"walked",1  ],
    ["mother","1","mama",1,"mom",1,"moms",1,"mommy",1  ],
    ["sticking","1","sticky",1,"stick",1,"sticks",1,"stuck",1  ],
    ["when would","1","which of",1,"which the",1,"very many",1,"when will",1  ],
    ["ordered","1","ordering",1,"orderly",2,"order",1,"orders",1  ],
    ["during the","1","early in",1,"either of",1,"down from",1,"each of",1  ],
    ["specialize","1","specialty",1,"special",1,"specials",1,"specially",1  ],
    ["right field","1","home plate",1,"the bull pen",1,"the pitcher's mound",1,"the dugout",1  ],
    ["unlike","1","like",1,"likes",1,"liked",1,"liking",1  ],
    ["scrambled","1","scrambling",1,"scrambler",1,"scramble",1,"scrambles",1  ],
    ["worms","1","wormed",1,"worming",4,"wormy",1,"worm",1  ],
    ["peaky","1","peak",1,"peaks",1,"peaked",2,"peaking",1  ],
    ["Ivan the Terrible","1","Wild \"I\" Name",1,"Isadora Duncan",1,"Incredible Isabel",1,"Isaac Newton",1  ],
    ["even","1","yes",1,"no",1,"maybe",1,"or",1  ],
    ["secrets","1","secretive",1,"secretly",2,"secreting",1,"secret",1  ],
    ["flipped","1","flipping",1,"flipper",1,"flip",1,"flips",1  ],
    ["you","1","them",1,"it",1,"I",1,"me",1  ],
    ["many","1","just in",1,"just about",1,"left",1,"left of",1  ],
    ["because of","1","another of",1,"before the",1,"around the",1,"away from",1  ],
    ["numerical","4","number",1,"numbers",1,"numbered",1,"numbering",1  ],
    ["","0","Wild Card",0,"Wild Card",0,"Wild Card",0,"Wild Card",0  ],
    ["on","1","at",1,"as",1,"and",1,"in",1  ],
    ["trick","1","tricks",1,"tricked",1,"tricking",1,"trickery",2  ],
    ["what","1","by",1,"this",1,"or",1,"but",1  ],
    ["to","1","a(n)",1,"and",1,"the",1,"of",1  ],
    ["to","1","a(n)",1,"and",1,"the",1,"of",1  ],
    ["my","1","mine",1,"your",1,"yours",1,"theirs",1  ],
    ["to","1","a(n)",1,"and",1,"the",1,"of",1  ],
    ["girls","1","girlish",2,"girl",1,"daddy's girl",1,"girly",1  ],
    ["dipping","1","dipped",1,"dips",1,"dip",1,"dipper",1  ],
    ["loves","1","loved",1,"loving",1,"lover",2,"love",1  ],
    ["Helpful Harry","1","Harpo Marx",1,"Wild \"H\" Name",1,"Harry Potter",1,"Helen of Troy",1  ],
    ["","0","Wild Card",0,"Wild Card",0,"Wild Card",0,"Wild Card",0  ],
    ["mine","1","my",1,"theirs",1,"yours",1,"your",1  ],
    ["bagged","1","bags",1,"bag",1,"bagger",2,"bagging",1  ],
    ["almost the","1","above the",1,"about the",1,"already the",1,"also the",1  ],
    ["that","1","with",1,"as",1,"in",1,"on",1  ],
    ["growing","1","grown",1,"grow",1,"grows",1,"grew",1  ],
    ["grouping","1","groupies",1,"group",1,"groups",1,"grouped",1  ],
    ["jammed","1","jamming",1,"jammer",1,"jam",1,"jams",1  ],
    ["mixed","1","mixing",1,"mixer",1,"mix",1,"mixes",1  ],
    ["such a(n)","1","sure of",1,"too",1,"through the",1,"under the",1  ],
    ["ours","1","her",1,"hers",1,"his",1,"theirs",1  ],
    ["doubtful","2","doubt",1,"doubts",1,"doubted",1,"doubting",1  ],
    ["needs","1","needed",1,"needing",1,"needy",1,"need",1  ],
    ["life","1","live",1,"lives",1,"lived",1,"living",1  ],
    ["we","1","they",1,"he",1,"she",1,"it",1  ],
    ["freezing","1","frozen",1,"freeze",1,"freezes",1,"froze",1  ],
    ["snakey","1","snakier",2,"snakes",1,"snake",1,"snakiest",1  ],
    ["alienating","2","alienated",2,"alienate",1,"aliens",1,"alien",1  ],
    ["cut","1","cutlery",1,"cutter",2,"cutting",1,"cuts",1  ],
    ["","0","Wild Card",0,"Wild Card",0,"Wild Card",0,"Wild Card",0  ],
    ["verklempt","4","schtick",1,"spiel",1,"tchotchkes",1,"schmutz",2  ],
    ["akimbo","4","stomping",1,"on tiptoe",1,"marching",1,"limping",1  ],
    ["Lord British","1","Dupre",1,"Shamino",1,"magic carpet",1,"orrery",2  ],
    ["one","1","two",1,"several",1,"too many",1,"none",1  ],
    ["WiFi","1","5G",1,"technology",1,"technological",1,"internet",1  ],
    ["adjacent to","1","surrounding",1,"asymptotic to",1,"throughout",1,"in lieu of",2  ],
    ["litigious","2","lawful",1,"truthfully",1,"litigants",2,"lawyer",1  ],
    ["PJ","2","Ben",2,"Otter",1,"Teri",1,"Shawn",1  ],
    ["Christmas Eve","1","Arbor Day",1,"Cinco de Mayo",1,"the Ides of March",1,"Leap Day",1  ],
    ["don't","1","wasn't",1,"can't",1,"shouldn't",1,"shan't",2  ],
    ["sing","1","singer",1,"singing",1,"tone-deaf",1,"sang",1  ],
    ["password","1","protected",1,"authorize",1,"email",1,"login",1  ],
    ["your","1","the",1,"someone's",1,"nobody",1,"everyone",1  ],
    ["favorite","1","favor",1,"prefer",1,"preference",2,"idol",1  ],
    ["rice","1","riced",1,"linguine",1,"marzipan",2,"ricey",1  ],
    ["NPR","2","the media",1,"fake news",1,"journalistic",2,"broadcasted",1  ],
    ["went","1","spent",1,"lent",1,"bent",1,"reinvent",1  ],
    ["flies",1,"flew",1,"flying",1,"fly",1,"flier",1  ],
    ["",0,"Wild Card",0,"Wild Card",0,"Wild Card",0,"Wild Card",0  ],
    ["flatter",1,"flattest",1,"flatten",2,"flattened",2,"flat",1  ],
    ["slicker",1,"slick",1,"slicks",1,"slicked",2,"slicking",1  ],
    ["juices",1,"juiced",1,"jucier",2,"juciest",1,"juice",1  ],
    ["Pope Paul VI",1,"Peggy Parish",1,"Pat Paulsen",1,"Wild \"P\" Name",1,"Pretty Paula",1  ],
    ["by",1,"this",1,"or",1,"but",1,"what",1  ],
    ["easy",1,"easier",1,"easiest",1,"easily",2,"ease",1  ],
    ["the merry-go-round",1,"the playground",1,"the monkey bars",1,"the swing set",1,"the ladder",1  ],
    ["lunches",1,"lunching",2,"lunched",4,"luncheon",1,"lunch",1  ],
    ["ours",1,"her",1,"hers",1,"his",1,"theirs",1  ],
    ["Delaware",1,"New Jersey",1,"Florida",1,"Connecticut",1,"Massachussetts",1  ],
    ["carrier",1,"carry",1,"carries",1,"carried",1,"carrying",1  ],
    ["richer",1,"richest",2,"rich",1,"riches",1,"richly",1  ],
    ["soak",1,"soaker",1,"soaks",1,"soaked",1,"soaking",1  ],
    ["a(n)",1,"and",1,"of",1,"the",1,"to",1  ],
    ["a(n)",1,"and",1,"of",1,"the",1,"to",1  ],
    ["and a(n)",1,"and",1,"and as",1,"and in",1,"and the",1  ],
    ["lips",1,"lip",1,"lipped",1,"lip lock",1,"lippy",4  ],
    ["theirs",1,"yours",1,"my",1,"mine",1,"your",1  ],
    ["cried",1,"cries",1,"cry",1,"crying",1,"crier",2  ],
    ["falls",1,"fell",1,"falling",1,"fallen",1,"fall",1  ],
    ["will be",1,"am",1,"was",1,"are",1,"is",1  ],
    ["Edgar Degas",1,"Ernest Hemmingway",1,"Eagle Eye Evan",1,"Wild \"E\" Name",1,"Elvis",1  ],
    ["fill",1,"fills",1,"filled",1,"full",1,"filling",1  ],
    ["a(n)",1,"and",1,"of",1,"the",1,"to",1  ],
    ["break",1,"breaking",1,"broken",1,"broke",1,"breaks",1  ],
    ["Tipsy Tallulah",1,"Truman Capote",1,"Wild \"T\" Name",1,"Theodore Roosevelt",1,"Tim McEnery",1  ],
    ["the classroom",1,"the principal's office",1,"the FBLA room",1,"the teacher's lounge",1,"the band room",1  ],
    ["gulping",1,"gulper",1,"gulp",1,"gulps",1,"gulped",1  ],
    ["Prince William",1,"William Bishop",1,"Winston Churchill",1,"Willy T",1,"Wild \"W\" Name",1  ],
    ["yes",1,"no",1,"maybe",1,"or",1,"even",1  ],
    ["smelled",1,"smelling",1,"smelly",1,"smell",1,"smells",1  ],
    ["an in",1,"as of",1,"as",1,"as a(n)",1,"as the",1  ],
    ["Sugar Ray Robinson",1,"Rembrandt",1,"Wild \"R\" Name",1,"Rachel Maddow",2,"Rey Palpatine",1  ],
    ["calls",1,"call",1,"caller",1,"called",1,"calling",1  ],
    ["ducky",2,"duck",1,"ducks",1,"ducked",1,"ducking",1  ],
    ["skips",1,"skip",1,"skipped",1,"skipping",1,"skipper",2  ],
    ["moment",1,"moments",1,"momentuous",1,"momentary",1,"momentarily",1  ],
    ["race",1,"races",1,"raced",1,"racing",1,"racer",1  ],
    ["may be",1,"middle of",1,"more of",1,"both of",1,"must have",1  ],
    ["",0,"Wild Card",0,"Wild Card",0,"Wild Card",0,"Wild Card",0  ],
    ["spit",1,"spits",1,"spat",1,"spitting",1,"spitter",1  ],
    ["sharp",1,"sharpening",1,"sharpened",1,"sharpen",1,"sharply",1  ],
    ["accents",1,"accented",1,"accent",1,"accentuated",2,"accenting",2  ],
    ["planner",1,"planning",1,"planned",1,"plans",1,"plan",1  ],
    ["very many",1,"when will",1,"when would",1,"which of",1,"which the",1  ],
    ["was",1,"will be",1,"am",1,"is",1,"are",1  ],
    ["sure of",1,"too",1,"through the",1,"under the",1,"such a(n)",1  ],
    ["knucklehead",1,"knuckly",1,"knuckles",1,"knuckled",1,"knuckling",1  ],
    ["did",1,"doesn't",1,"didn't",1,"do",1,"does",1  ],
    ["how to",1,"in",1,"inside of",1,"instead of",1,"into the",1  ],
    ["bouncer",2,"bounce",1,"bouncing",1,"bounced",1,"bounces",1  ],
    ["booms",1,"boomed",1,"booming",1,"boomer",1,"booms",1  ],
    ["he",1,"she",1,"it",1,"we",1,"they",1  ],
    ["and as",1,"and in",1,"and the",1,"and a(n)",1,"and",1  ],
    ["dripper",1,"dripping",1,"drip",1,"drips",1,"dripped",1  ],
    ["he",1,"she",1,"it",1,"we",1,"they",1  ],
    ["which the",1,"which of",1,"very many",1,"when will",1,"when would",1  ],
    ["skinning",1,"skinny",1,"skin",1,"skins",1,"skinned",1  ],
    ["the dentist's office",1,"the labratory",1,"the doctor's office",1,"the waiting room",1,"the examining room",1  ],
    ["creep",1,"creeps",1,"creepy",1,"creeping",1,"crept",1  ],
    ["noises",1,"noisy",1,"noise",1,"noisiest",1,"noisier",1  ],
    ["fakes",1,"fake",1,"faker",1,"faked",1,"faking",1  ],
    ["dark",1,"farker",1,"darkens",2,"darkest",1,"darkening",1  ],
    ["weirdly",4,"weird-o",1,"weird",1,"weirder",1,"weirdest",1  ],
    ["will be",1,"am",1,"is",1,"are",1,"was",1  ],
    ["loud mouth",1,"loud",1,"louder",1,"loudest",1,"loudly",1  ],
    ["monkeying around",1,"monkey",1,"monkeys",1,"monkeyed",1,"monkeying",1  ],
    ["a(n)",1,"and",1,"of",1,"the",1,"to",1  ],
    ["yellow",1,"yellows",1,"yellowed",1,"yellowing",1,"yellowish",1  ],
    ["hers",1,"his",1,"theirs",1,"ours",1,"her",1  ],
    ["mover",1,"move",1,"moves",1,"moved",1,"moving",1  ],
    ["larger",1,"largely",2,"enlarged",2,"large",1,"largest",1  ],
    ["throw out",2,"throwing",1,"threw",1,"throws",1,"throw",1  ],
    ["spooky",1,"spook",1,"spooks",1,"spooked",1,"spooking",1  ],
    ["softly",1,"soften",1,"softest",1,"soft",1,"softer",1  ],
    ["",0,"Wild Card",0,"Wild Card",0,"Wild Card",0,"Wild Card",0  ],
    ["off of",1,"on the",1,"or the",1,"over the",1,"next",1  ],
    ["sauces",1,"saucy",1,"sauced",1,"saucing up",2,"sauce",1  ],
    ["quitter",2,"quit",1,"quitted",2,"quitting",1,"acquitted",4  ],
    ["when will",1,"when would",1,"which of",1,"which the",1,"very many",1  ],
    ["oil",1,"oils",1,"oily",1,"oiling",1,"oiled",1  ],
    ["Charles Dickens",1,"Count Dracula",1,"Christopher Columbus",1,"Wild \"C\" Name",1,"Clark Gable",1  ],
    ["a(n)",1,"and",1,"of",1,"the",1,"to",1  ],
    ["hard",1,"harder",1,"hardest",1,"hardening",1,"harden",1  ],
    ["leg up",1,"leg lift",2,"leg",1,"legs",1,"leggy",1  ],
    ["moods",1,"moody",1,"mood",1,"moodier",4,"moodiest",1  ],
    ["mysterious",2,"mysteriously",4,"mystery",1,"mystified",2,"Mr. T",1  ],
    ["",0,"Wild Card",0,"Wild Card",0,"Wild Card",0,"Wild Card",0  ],
    ["flame",1,"flames",1,"flame-like",2,"flaming",1,"flamed",1  ],
    ["junk",1,"junks",1,"junky",1,"junkier",1,"junkiest",1  ],
    ["locking",1,"locker",2,"lock",1,"locks",1,"locked",1  ],
    ["a(n)",1,"and",1,"of",1,"the",1,"to",1  ],
    ["doctors",1,"doctored",1,"doctoring",1,"doctoral",4,"doctor",1  ],
    ["pigs",1,"pigged",1,"pigging",1,"piggy",1,"pig",1  ],
    ["got",1,"getting",1,"gotten",1,"get",1,"gets",1  ],
    ["snapped",1,"snapping",1,"snappy",1,"snap",1,"snaps",1  ],
    ["William Shakespeare",1,"Willy Piper",1,"Wonderful Walt",1,"Wild \"W\" Name",1,"William Wyler",1  ],
    ["peeps",1,"peeped",1,"peeping",1,"peeper",2,"peep",1  ],
    ["jerking",1,"jerky",2,"jerk",1,"jerks",1,"jerked",1  ],
    ["the conservatory",1,"the library",1,"the billiard room",1,"the lounge",1,"the study",1  ],
    ["just in",1,"just about",1,"left",1,"left of",1,"many",1  ],
    ["closed",1,"closing",1,"closer",2,"close",1,"closes",1  ],
    ["just in",1,"just about",1,"left",1,"left of",1,"many",1  ],
    ["next",1,"off of",1,"on the",1,"or the",1,"over the",1  ],
    ["I",1,"me",1,"you",1,"them",1,"it",1  ],
    ["a(n)",1,"and",1,"of",1,"the",1,"to",1  ],
    ["the dog spa",1,"the dude ranch",1,"the dog pound",1,"the race track",1,"the vet's office",1  ],
    ["swings",1,"swung",1,"swinging",1,"swinger",1,"swing",1  ],
    ["highly",1,"height",1,"high",1,"higher",1,"highest",1  ],
    ["",0,"Wild Card",0,"Wild Card",0,"Wild Card",0,"Wild Card",0  ],
    ["freed",1,"freeing",1,"freely",1,"free",1,"frees",1  ],
    ["in",1,"on",1,"that",1,"with",1,"as",1  ],
    ["the bar",1,"the lounge",1,"the waiting area",1,"the lido deck",1,"the kitchen",1  ],
    ["spoiling",1,"spoiler",1,"spoil",1,"spoils",1,"spoiled",1  ],
    ["gassing",1,"gassy",1,"gas",1,"gassed",2,"gasses",1  ],
    ["in the yard",1,"on the porch",1,"at the neighbor's",1,"in the street",1,"on the sidewalk",1  ],
    ["teach",1,"teahces",1,"taught",1,"teaching",2,"teacher",1  ],
    ["",0,"Wild Card",0,"Wild Card",0,"Wild Card",0,"Wild Card",0  ],
    ["mashed",1,"mashing",1,"masher",1,"mash",1,"mashes",1  ],
    ["sneaking",1,"sneaky",4,"sneak",1,"sneaks",1,"sneaked",1  ],
    ["Alfred Hitchcock",1,"Alexander Graham Bell",1,"Abraham Lincoln",1,"Wild \"A\" Name",1,"Artisting Ashley",1  ],
    ["acted",1,"acts",1,"acting",1,"action",1,"act",1  ],
    ["a(n)",1,"and",1,"of",1,"the",1,"to",1  ],
    ["grabby",2,"grab",1,"grabs",1,"grabbed",1,"grabbing",1  ],
    ["swims",1,"swam",1,"swimming",1,"swimmer",1,"swim",1  ],
    ["gave",1,"giving",1,"given",1,"give",1,"gives",1  ],
    ["left of",1,"many",1,"just in",1,"just about",1,"left",1  ],
    ["down from",1,"each of",1,"during the",1,"early in",1,"either of",1  ],
    ["soundly",1,"sound",1,"sounds",1,"sounded",1,"sounding",1  ],
    ["ganged up",4,"ganging",1,"gangster",1,"gang",1,"gangs",1  ],
    ["Kalamazoo",1,"Far, far away",1,"The Caribbean",1,"Siberia",1,"Timbuktu",1  ],
    ["changes",1,"changed",1,"changing",1,"changer",2,"change",1  ],
    ["a(n)",1,"and",1,"of",1,"the",1,"to",1  ],
    ["present",1,"presents",1,"presented",1,"presenting",1,"presently",2  ],
    ["grossing",1,"grossest",1,"gross",1,"grosses",1,"grossed",1  ],
    ["produce",1,"produces",1,"produced",1,"producing",1,"producer",2  ],
    ["mistaken",1,"mistaking",1,"mistakenly",2,"mistake",1,"mistakes",1  ],
    ["eat",1,"eating",1,"eats",1,"ate",1,"eaten",1  ],
    ["balance",1,"balanced",1,"balances",1,"balancing",1,"balanced diet",1  ],
    ["at",1,"as",1,"and",1,"in",1,"on",1  ],
    ["strongest",1,"strongly",1,"stronger",1,"strength",1,"strong",1  ],
    ["nuttier",4,"nuttiest",2,"nut",1,"nuts",1,"nutty",1  ],
    ["bites",1,"bit",1,"biting",1,"bitten",1,"bite",1  ],
    ["",0,"Wild Card",0,"Wild Card",0,"Wild Card",0,"Wild Card",0  ],
    ["parked",1,"parking",1,"parker",2,"park",1,"parks",1  ],
    ["color",1,"colors",1,"colored",1,"coloring",1,"colorful",2  ],
    ["Napoleon",1,"Newt Gingrich",1,"Neat Nick",1,"Noisy Natalie",1,"Wild \"N\" Name",1  ],
    ["instead of",1,"into the",1,"how to",1,"in",1,"inside of",1  ],
    ["Neverland",1,"never-ending",1,"never mind",1,"nevertheless",1,"never",1  ],
    ["in the stands",1,"the zero yard line",1,"on the bench",1,"in the end zone",1,"the red zone",1  ],
    ["ached",1,"aching",1,"achy",1,"ache",1,"aches",1  ],
    ["Al Capone",1,"Annie Oakley",1,"Wild \"A\" Name",1,"Annie Warbucks",1,"Albert Einstein",1  ],
    ["until it",1,"until the",1,"upon the",1,"what the",1,"what if",1  ],
    ["they",1,"he",1,"she",1,"it",1,"we",1  ],
    ["purple",1,"red",1,"orange",1,"blue",1,"green",1  ],
    ["slippery",1,"slip",1,"slipped",1,"slipping",1,"slips",1  ],
    ["child",1,"man",1,"men",1,"woman",1,"women",1  ],
    ["player",1,"play",1,"plays",1,"played",1,"playing",1  ],
    ["as a(n)",1,"as in",1,"as of",1,"as the",1,"as",1  ],
    ["except for",1,"far from",1,"for",1,"from",1,"enough of",1  ],
    ["Wild \"D\" Name",1,"Donald Trump",1,"Dr. MLK, Jr.",1,"Dannel Malloy",1,"Davy Crockett",1  ],
    ["learn",1,"learns",1,"learned",1,"learning",1,"learner",1  ],
    ["a(n)",1,"and",1,"of",1,"the",1,"to",1  ],
    ["the hospital",1,"six feet under",1,"a traffic jam",1,"the dentist's chair",1,"a snowstorm",1  ],
    ["fantasize",1,"fantasized",4,"fantasies",1,"fantasy",1,"fantasizing",1  ],
    ["experiment",1,"experimental",2,"experimenting",1,"experimented",1,"experiments",1  ],
    ["single",1,"one",1,"once",1,"only",1,"solitaire",4  ],
    ["a(n)",1,"and",1,"of",1,"the",1,"to",1  ],
    ["a(n)",1,"and",1,"of",1,"the",1,"to",1  ],
    ["squirrel",1,"squirrels",1,"squirreled",4,"squirreling",1,"squirrelly",2  ],
    ["making",1,"maker",1,"make",1,"makes",1,"made",1  ],
    ["them",1,"it",1,"I",1,"me",1,"you",1  ],
    ["a(n)",1,"and",1,"of",1,"the",1,"to",1  ],
    ["strangers",1,"strange",1,"stranger",1,"strangest",1,"strangely",1  ],
    ["party",1,"parties",1,"partied",1,"partying",1,"partier",1  ],
    ["bottom of",1,"behind the",1,"below the",1,"better than",1,"between the",1  ],
    ["brown",1,"taupe",1,"lavender",1,"mauve",2,"pink",1  ],
    ["Krazy Kyle",1,"Kylo Ren",1,"Wild \"K\" Name",1,"Mrs. Kenefick",1,"Katherine Hepburn",1  ],
    ["jazzed",2,"jazzing",1,"jazzy",2,"jazz",1,"jazzes",1  ],
    ["builds",1,"built",1,"building",1,"builder",2,"build",1  ],
    ["a(n)",1,"and",1,"of",1,"the",1,"to",1  ],
    ["minding",4,"minded",1,"minds",1,"mind",1,"never mind",1  ],
    ["Jupiter",1,"Pluto",1,"Mars",1,"Venus",1,"Mercury",1  ],
    ["fish",1,"fishy",2,"fishes",1,"fished",1,"fishing",1  ],
    ["cooks",1,"cooked",1,"cooking",1,"cooker",1,"cook",1  ],
    ["had",1,"having",1,"will have",1,"has not",1,"has",1  ],
    ["banked",1,"banking",1,"banker",1,"bank",1,"banks",1  ],
    ["rockets",1,"rocketed",1,"rocketing",4,"rocket man",1,"rocket",1  ],
    ["cheesy",1,"cheesier",1,"cheesiest",1,"cheese",1,"cheeses",1  ],
    ["Brendan",1,"Wild \"B\" Name",1,"Buffalo Bill",1,"Billy the Kid",1,"Bart Simpson",1  ],
    ["fluidity",2,"fluidness",4,"fluid",1,"fluids",1,"fuildly",1  ],
    ["Wild \"H\" Name",1,"Harry Truman",1,"Han Solo",1,"Hamilton",1,"Haley's Comet",1  ],
    ["",0,"Wild Card",0,"Wild Card",0,"Wild Card",0,"Wild Card",0  ],
    ["quietly",1,"quieting",4,"quiet",1,"quieter",1,"quietest",1  ],
    ["ratty",4,"rat",1,"rats",1,"ratted",1,"ratting",1  ],
    ["crusher",2,"crush",1,"crushes",1,"crushed",1,"crushing",1  ],
    ["as",1,"and",1,"in",1,"on",1,"at",1  ],
    ["a hammock",1,"the pool",1,"the beach",1,"a barbeque",1,"a picnic",1  ],
    ["lit",1,"lighted",1,"lighten",1,"light",1,"lighs",1  ],
    ["",0,"Wild Card",0,"Wild Card",0,"Wild Card",0,"Wild Card",0  ],
    ["eye",1,"eyes",1,"eyed",1,"eyeing",1,"eye glasses",1  ],
    ["before the",1,"another of",1,"around the",1,"away from",1,"because of",1  ],
    ["seen",1,"seeing",1,"seer",4,"see",1,"sees",1  ],
    ["copy",1,"copies",1,"copied",1,"copying",1,"copycat",1  ],
    ["sloppiest",1,"slop",1,"slops",1,"slopped",2,"sloppy",1  ],
    ["Wild \"S\" Name",1,"Simon Says",1,"Superior Scott",1,"Steve Carell",1,"The Supreme Court",1  ],
    ["roughs",1,"roughly",1,"rougher",1,"roughest",1,"rough",1  ],
    ["blasts",1,"blasted",1,"blasting",1,"blaster",2,"blast",1  ],
    ["next",1,"off of",1,"or the",1,"on the",1,"over the",1  ],
    ["warm",1,"warms",1,"warmed",2,"warmer",1,"warmest",1  ],
    ["tickling",1,"tickler",2,"tickle",1,"tickles",1,"tickled",1  ],
    ["lemon",1,"lemons",1,"lemony",2,"lemon scented",1,"sour lemon",1  ],
    ["yawner",2,"yawn",1,"yawns",1,"yawned",1,"yawning",1  ],
    ["held",1,"holding",1,"holder",2,"hold",1,"holds",1  ],
    ["that",1,"with",1,"as",1,"in",1,"on",1  ],
    ["Dancin' Dave",1,"Wild \"D\" Name",1,"Dr. Anthony Fauci",1,"Dunder Mifflin",1,"Donald Duck",1  ],
    ["sending",1,"sender",1,"send",1,"sends",1,"sent",1  ],
    ["kinder",1,"kind",1,"kinds",1,"kindness",1,"kindly",1  ],
    ["a(n)",1,"and",1,"of",1,"the",1,"to",1  ],
    ["most beautiful",1,"beauty",1,"beautiful",1,"beautifully",1,"more beautiful",1  ],
    ["wettest",1,"wet",1,"wets",1,"wetting",1,"wetter",1  ],
    ["on",1,"at",1,"as",1,"and",1,"in",1  ],
    ["a(n)",1,"and",1,"of",1,"the",1,"to",1  ],
    ["what",1,"by",1,"this",1,"or",1,"but",1  ],
    ["squeezes",1,"squeezed",1,"squeezing",1,"squeezer",2,"squeeze",1  ],
    ["necklace",1,"neck",1,"necks",1,"necked",1,"necking",1  ],
    ["toy",1,"toys",1,"toyed",1,"toying",4,"the toy store",1  ],
    ["",0,"Wild Card",0,"Wild Card",0,"Wild Card",0,"Wild Card",0  ],
    ["deep",1,"deeply",1,"deepen",1,"deepens",1,"deepening",2  ],
    ["drinker",1,"drink",1,"drinks",1,"drunk",1,"drinking",1  ],
    ["sentence",1,"sentences",1,"sentenced",1,"sentencing",1,"sentencer",1  ],
    ["babying",1,"baby",1,"baby sitter",1,"babied",1,"babies",1  ],
    ["wish",1,"wisher",1,"wishes",1,"wished",1,"wishing",1  ],
    ["team",1,"teamed",1,"teaming up",1,"team leader",2,"Microsoft Teams",1  ],
    ["stink",1,"stunk",1,"stunks",1,"stinky",1,"stinking",4  ],
    ["Wild \"C\" Name",1,"Charlie Chaplin",1,"Carl Jung",1,"Cute Christine",1,"Cy Young",1  ],
    ["a(n)",1,"and",1,"of",1,"the",1,"to",1  ],
    ["opened",1,"open",1,"opens",1,"opening",1,"openly",1  ],
    ["scooping",1,"scooper",1,"scoop",1,"scoops",1,"scooped",1  ],
    ["having",1,"will have",1,"has not",1,"has",1,"had",1  ],
    ["pirated",1,"pirating",1,"pirates",1,"pirate",1,"pirate-like",1  ],
    ["flash",1,"flashes",1,"flashed",1,"flashy",2,"flashing",2  ],
    ["gum",1,"gums",1,"gummed",1,"gumming up",1,"gummy",2  ],
    ["grinding",1,"grinder",1,"grind",1,"grinds",1,"ground",1  ],
    ["figures",1,"figured",1,"figuring",1,"figured out",2,"figure",1  ],
    ["cools",1,"cooled",1,"cooling",1,"cooler",2,"cool",1  ],
    ["fancy",1,"fancies",1,"fancied",2,"fancier",1,"fanciest",1  ],
    ["cat",1,"cats",1,"catty",1,"cat-like",2,"Cats (2019)",1  ],
    ["a(n)",1,"and",1,"of",1,"the",1,"to",1  ],
    ["having",1,"will have",1,"has not",1,"has",1,"had",1  ],
    ["average",1,"averages",1,"averaged",1,"average age",2,"averaging",1  ],
    ["come on",1,"come",1,"comes",1,"came",1,"coming",1  ],
    ["facer",4,"face",1,"faces",1,"faced",1,"facing",1  ],
    ["New Britain",1,"Vero Beach",1,"Acton",1,"Maynard",1,"Southington",1  ],
    ["lowest",1,"lowly",1,"lowered",1,"low",1,"lower",1  ],
    ["already the",1,"about the",1,"above the",1,"almost the",1,"also the",1  ],
    ["hairy",1,"harier",1,"hariest",1,"hair",1,"hairs",1  ],
    ["by",1,"this",1,"or",1,"but",1,"what",1  ],
    ["New Mexico",1,"Tenessee",1,"Alabama",1,"Kansas",1,"South Dakota",1  ],
    ["lumps",1,"lumped",1,"lumpy",1,"lumpiest",1,"lump",1  ],
    ["in",1,"on",1,"that",1,"with",1,"as",1  ],
    ["pages",1,"paged",1,"paging",1,"pager",1,"page",1  ],
    ["itched",1,"itching",1,"itchy",1,"itch",1,"itches",1  ],
    ["hog",1,"hogs",1,"hogged",1,"hogging",1,"hogger",1  ],
    ["the basement",1,"the bathroom",1,"the kitchen",1,"the den",1,"the garage",1  ],
    ["nose",1,"noses",1,"nosey",1,"nosing",1,"nosiest",1  ],
    ["rusher",2,"rush",1,"rushing",1,"rushed",1,"rushes",1  ],
    ["sand pile",1,"pile",1,"piles",1,"piled",1,"piling",1  ],
    ["twists",1,"twisted",1,"twisting",2,"twister",1,"twist",1  ],
    ["through the",1,"under the",1,"such a(n)",1,"sure of",1,"too",1  ],
    ["crashing",1,"crasher",2,"crash",1,"crashes",1,"crashed",1  ],
    ["America",1,"American",1,"Americans",1,"American style",1,"Americana",1  ],
    ["started",1,"starting",1,"starter",1,"start",1,"starts",1  ],
    ["bangs",1,"gangs",1,"banged",1,"banging",1,"banger",2  ],
    ["until the",1,"upon the",1,"what the",1,"what if",1,"until it",1  ],
    ["Roaring Ricky",1,"Roald Dahl",1,"Rex Parker",1,"Wild \"R\" Name",1,"Ronald McDonald",1  ],
    ["littler",1,"littlest",1,"tiny",1,"tiniest",1,"little",1  ],
    ["spills",1,"spilled",1,"spilling",1,"spiller",1,"spill",1  ],
    ["wilds",1,"wilder",1,"wildest",1,"wildly",1,"wild",1  ],
    ["slide",1,"slides",1,"slid",1,"sliding",1,"slider",1  ],
    ["rocks",1,"rock",1,"rocked",1,"rocking",1,"rocky",1  ],
    ["pulled",1,"pulling",1,"puller",2,"pull",1,"pulls",1  ],
    ["the",1,"to",1,"a(n)",1,"and",1,"of",1  ],
    ["printing",2,"printer",2,"print",1,"prints",1,"printed",1  ],
    ["the",1,"to",1,"a(n)",1,"and",1,"of",1  ],
    ["the cubicle",1,"the mailroom",1,"the boss's office",1,"the office",1,"the lunch room",1  ],
    ["",0,"Wild Card",0,"Wild Card",0,"Wild Card",0,"Wild Card",0  ],
    ["passer",2,"pass",1,"passes",1,"passed",1,"passing",1  ],
    ["fumbler",2,"fumble",1,"fumbling",1,"fumbled",1,"fumbles",1  ],
    ["freaks",1,"freak",1,"freakier",1,"friekiest",1,"freaked out",2  ],
    ["fast",1,"fastest",1,"faster",1,"fasting",2,"fasted",1  ],
    ["firing",1,"fiery",1,"fire",1,"fires",1,"fired",1  ],
    ["boxes",1,"boxed",1,"boxing",1,"boxer",2,"box",1  ],
    ["peppery",2,"pepper",1,"peppers",1,"peppering",2,"peppered",1  ],
    ["hot",1,"hottest",1,"hotly",1,"heat",1,"hot",1  ],
    ["King John",1,"Wild \"K\" Name",1,"Ken Jennings",1,"Kevin",1,"Kamala Harris",1  ],
    ["the pool",1,"the amusement park",1,"the aquarium",1,"the museum",1,"the circus",1  ],
    ["fixiest",2,"fix",1,"foxes",1,"foxier",2,"foxed",4  ],
    ["chicken-like",2,"chicken",1,"chickens",1,"chickened out",4,"chickening out",4  ],
    ["took",1,"takes",1,"taken",1,"taker",1,"take",1  ],
    ["meant",1,"means",1,"meaning",1,"meany",1,"mean",1  ],
    ["",0,"Wild Card",0,"Wild Card",0,"Wild Card",0,"Wild Card",0  ],
    ["flunk",1,"flunks",1,"flunked",1,"flunking",4,"flunky",2  ],
    ["what if",1,"until it",1,"until the",1,"upon the",1,"what the",1  ],
    ["drawer",1,"draw",1,"draws",1,"drew",1,"drawing",1  ],
    ["on",1,"that",1,"with",1,"as",1,"in",1  ],
    ["",0,"Wild Card",0,"Wild Card",0,"Wild Card",0,"Wild Card",0  ],
    ["longed",1,"longing",1,"longingly",1,"long",1,"longs",1  ],
    ["sneezed",1,"sneezing",1,"sneezer",2,"sneeze",1,"sneezes",1  ],
    ["the trail",1,"the barn",1,"the stable",1,"the stall",1,"the ring",1  ],
    ["because of",1,"another of",1,"before the",1,"around the",1,"away from",1  ],
    ["and",1,"of",1,"the",1,"to",1,"a(n)",1  ],
    ["through the",1,"under the",1,"such a(n)",1,"sure of",1,"too",1  ],
    ["snow",1,"snowed",1,"snows",1,"snowing",1,"snowy",1  ],
    ["zips",1,"zipped",1,"zippy",1,"zip",1,"zipper",1  ],
    ["you'd",1,"you",1,"your",1,"yours",1,"you're",1  ],
    ["kept",1,"keeping",1,"keeper",1,"keep",1,"keeps",1  ],
    ["your",1,"yours",1,"you're",1,"you'd",1,"you",1  ],
    ["out",1,"outs",1,"outing",1,"outside",1,"outer",1  ],
    ["Booker T. Washington",1,"Wild \"B\" Name",1,"Bernard",1,"Betsy Ross",1,"UCB",1  ],
    ["dream",1,"dreams",1,"dreamer",2,"dreaming",1,"dreamt",1  ],
    ["soaped",1,"soaping",2,"soaps",1,"soap",1,"soapy",1  ],
    ["madder",1,"maddest",1,"madden",2,"maddening",2,"mad",1  ],
    ["of",1,"the",1,"to",1,"a(n)",1,"and",1  ],
    ["was",1,"will be",1,"am",1,"is",1,"are",1  ],
    ["bottom",1,"bottoms",1,"bottomed out",4,"bottoming out",4,"bottom feeder",1  ],
    ["questions",1,"questioned",1,"questioning",1,"questioningly",4,"question",1  ],
    ["looking",1,"looker",1,"look",1,"looks",1,"looked",1  ],
    ["clearly",2,"clear",1,"clearing",1,"cleared",1,"clearly",2  ],
    ["",0,"Wild Card",0,"Wild Card",0,"Wild Card",0,"Wild Card",0  ],
    ["powering",1,"powerful",1,"power",1,"powers",1,"powered",1  ],
    ["",0,"Wild Card",0,"Wild Card",0,"Wild Card",0,"Wild Card",0  ],
    ["most dangerous",1,"dangerous",1,"more dangerous",1,"dangerously",1,"danger",1  ],
    ["Greta Garbo",1,"Wild \"G\" Name",1,"Gregarious Greg",1,"Grover Cleveland",1,"Gunga Din",1  ],
    ["",0,"Wild Card",0,"Wild Card",0,"Wild Card",0,"Wild Card",0  ],
    ["grounder",2,"ground",1,"grounds",1,"grounded",1,"grounding",1  ],
    ["slowed",1,"slower",1,"slowly",1,"slow",1,"slows",1  ],
    ["troublemaker",1,"trouble",1,"troubles",1,"troubled",1,"troubling",1  ],
    ["Pablo Picasso",1,"Pretty Paul",1,"Wild \"P\" Name",1,"Paul Revere",1,"PJ",1  ],
    ["in",1,"on",1,"that",1,"with",1,"as",1  ]
];


let cards;

exports.getCards = () => {
    if(cards === undefined) {
        cards = transform();
    }
    return cards;
}

function transform() {

    let mappedCards = cardsRaw.map((value, index) => {
        let card = {
            id: index.toString(),
            words: [
                {
                    word: value[0],
                    points: parseInt(value[1], 10)
                },
                {
                    word: value[2],
                    points: parseInt(value[3], 10)
                },
                {
                    word: value[4],
                    points: parseInt(value[5], 10)
                },
                {
                    word: value[6],
                    points: parseInt(value[7], 10)
                },
                {
                    word: value[8],
                    points: parseInt(value[9], 10)
                }
            ]
        };
        return card;
    });
    console.log(mappedCards);
    return mappedCards;
};
import {getCards} from "./cardsRaw";

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import HandCard from "./HandCard";
import SentencePreview from "./SentencePreview";
import {Dialog, unsupportedProp} from "@material-ui/core";
import WaitingOverlay from "./WaitingOverlay";
import {db, submitSentence, updateSentence, useFirestoreQuery} from "./Firebase";


// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    flexShrink: 0,


    // change background color if dragging
    // background: isDragging ? 'lightgreen' : 'transparent',

    // styles we need to apply on draggables
    ...draggableStyle
});

const getHandStyle = isDraggingOver => ({
    background: isDraggingOver ? '#5d5d5d' : '#4b4b4b',
    flexGrow: 0,
    alignSelf: 'flex-end',
    display: 'flex',
    height: '206px',
    width: '100%',
    overflowX: 'scroll'
});

const getCanvasStyle = isDraggingOver => ({
    background: isDraggingOver ? '#2c8e55' : '#247345',
    display: 'flex',
    alignItems: 'flex-start',
    flexGrow: 0,
    overflow: 'scroll',
    height: '206px',
    width: '100%',
    overflowX: 'scroll'
});

export default class SentenceBuilder extends Component {


    getHand = () => {
        let cardsCopy = [ ...getCards() ];
        return this.props.hand.reduce((previousValue, currentValue) => {
            previousValue.push({ ...cardsCopy[currentValue], selectedIndex: 0 });
            return previousValue;
        }, []);
    }

    state = {
        items: this.getHand(),
        selected: [],
        submitted: false
    };

    /**
     * A semi-generic way to handle multiple lists. Matches
     * the IDs of the droppable container to the names of the
     * source arrays stored in the state.
     */
    id2List = {
        droppable: 'items',
        droppable2: 'selected'
    };

    handleSelection = (cardId, selectedIndex) => {

        let index = this.state.selected.findIndex(x => x.id === cardId)
        if (index > -1) {
            // console.log(`Selected index ${selectedIndex} on card ${cardId}`);
            let newSelected = [ ...this.state.selected ];
            newSelected[index].selectedIndex = selectedIndex;
            this.setState({selected: newSelected});
        }
    }

    handleWildUpdate = (cardId, newValue) => {
        let index = this.state.selected.findIndex(x => x.id === cardId)
        if (index > -1) {
            let newSelected = [ ...this.state.selected ];
            newSelected[index].words[0].word = newValue;
            this.setState({selected: newSelected});
        }
    }

    getList = id => this.state[this.id2List[id]];

    onDragEnd = result => {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                this.getList(source.droppableId),
                source.index,
                destination.index
            );

            let state = { items };

            if (source.droppableId === 'droppable2') {
                state = { selected: items };
            }

            this.setState(state);
        } else {
            const result = move(
                this.getList(source.droppableId),
                this.getList(destination.droppableId),
                source,
                destination
            );

            this.setState({
                items: result.droppable,
                selected: result.droppable2
            });
        }
    }

    isVowel = (x) => {
        return ("aeiouAEIOU".indexOf(x) !== -1);
    }

    currentSentence = () => {
        return this.state.selected.reduce( (previousValue, currentValue, currentIndex) => {
            let word = currentValue.words[currentValue.selectedIndex].word;
            if(word.length === 0) {
                return previousValue;
            }

            let articleIndex = word.indexOf("a(n)");
            let nextCard = this.state.selected[currentIndex + 1];
            if (articleIndex > -1 && nextCard !== undefined) {
                let nextWord = nextCard.words[nextCard.selectedIndex].word;
                console.log('next word: ', nextWord);
                if (this.isVowel(nextWord.charAt(0))) {
                    word = word.replace("a(n)", "an");
                } else {
                    word = word.replace("a(n)", "a");
                }
            }

            if (currentIndex === 0) {
                return `${word[0].toUpperCase()}${word.slice(1)}`;
            } else {
                return previousValue + ' ' + word;
            }
        }, '');
    }

    currentPointValue = () => {
        return this.state.selected.reduce( (previousValue, currentValue) => {
            return previousValue + currentValue.words[currentValue.selectedIndex].points;
        }, this.state.selected.length === 10 ? 5 : 0);
    }

    submitSentence = () => {
        submitSentence(this.props.player.id, this.currentSentence(), this.currentPointValue(), this.state.selected.map((value) => parseInt(value.id, 10)));
        this.setState({submitted: true});
    }

    updateSentencePunctuation = (sentence) => {
        updateSentence(this.props.player.id, sentence);
    }

    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    render() {
        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Dialog open={this.state.submitted === true} maxWidth={'100vw'}>
                    <WaitingOverlay sentence={this.currentSentence()} handlePunctuationUpdate={this.updateSentencePunctuation}/>
                </Dialog>
                <div style={{ display:'flex', flexDirection:'column', justifyContent: 'space-between', minHeight: '97%'}}>
                    <SentencePreview sentence={this.currentSentence()}
                                     points={this.currentPointValue()}
                                     bonus={this.state.selected.length == 10}
                                     handleSubmit={() => this.submitSentence()}/>
                    <Droppable droppableId="droppable2" direction="horizontal">
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                style={getCanvasStyle(snapshot.isDraggingOver)}>
                                {this.state.selected.map((item, index) => (
                                    <Draggable
                                        key={item.id}
                                        draggableId={item.id}
                                        index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}>
                                                <HandCard card={item} handleSelection={this.handleSelection} isDragging={snapshot.isDragging} handleWildUpdate={this.handleWildUpdate}/>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                    <div style={{flexGrow: 20, flexBasis: '10px'}}>
                    </div>
                    <Droppable droppableId="droppable" direction="horizontal">
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                style={getHandStyle(snapshot.isDraggingOver)}>
                                {this.state.items.map((item, index) => (
                                    <Draggable
                                        key={item.id}
                                        draggableId={item.id}
                                        index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}>
                                                <HandCard card={item} isDragging={snapshot.isDragging}/>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </div>

            </DragDropContext>
        );
    }
}

import {db, endSentenceBuilding, useFirestoreQuery} from "./Firebase";
import {
    Dialog,
    DialogTitle,
    List,
    CircularProgress,
    ListItemIcon,
    Icon,
    IconButton,
    ListItemSecondaryAction, Button
} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import React from "react";
import CheckIcon from '@material-ui/icons/Check';
import {Check} from "@material-ui/icons";
import Punctuator from "./Punctuator";


function WaitingOverlay({ sentence, handlePunctuationUpdate }) {

    const sentences = useFirestoreQuery(
        db.collection("sentences")
    );

    const players = useFirestoreQuery(
        db.collection("players")
    );

    return (
        <>
        <DialogTitle style={{textAlign: 'center'}}>Waiting for players</DialogTitle>
            <Punctuator sentence = {sentence} handlePunctuationUpdate={handlePunctuationUpdate}/>

            <List>
            {players.data !== undefined && players.data.map((player) => {
                    return ( <ListItem>{player.id}
                    <ListItemSecondaryAction edge="end">
                        {(sentences.data.filter((sentence => sentence.id === player.id)).length > 0) ? <CheckIcon/>
                            : <CircularProgress edge="end" size="20px"/>}
                    </ListItemSecondaryAction>
                    </ListItem>)
            })}
        </List>
            {(sentences.data !== undefined && players.data !== undefined && sentences.data.length >= players.data.length &&
                <Button style={{margin: '5px', padding: '5px'}} variant="contained" color="primary" onClick={() => endSentenceBuilding()}>View Sentences</Button>
            )}
            {(sentences.data !== undefined && players.data !== undefined && sentences.data.length < players.data.length &&
                <Button style={{margin: '5px', padding: '5px'}} variant="contained" color="secondary" onClick={() => endSentenceBuilding()}>End Round Early</Button>
            )}
            </>
    );

}

export default WaitingOverlay;
import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
    typography: {
        body2: {
            lineHeight: 1
        }
    }
})

export default theme
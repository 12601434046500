import firebase from "firebase";
import {useEffect, useRef, useReducer} from "react";
import shuffle from 'shuffle-array';
import { getCards } from './cardsRaw';
// Required for side-effects
import firestore from 'firebase/firestore';

const handSize = 10;

const firebaseConfig = {
    apiKey: "AIzaSyA7mik02BT0dCTStO9NqaythE7lSuVGeXs",
    authDomain: "sentence-c28f1.firebaseapp.com",
    projectId: "sentence-c28f1",
    storageBucket: "sentence-c28f1.appspot.com",
    messagingSenderId: "290632401350",
    appId: "1:290632401350:web:5b97fe920a8b72018f32c7"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();

// Reducer for hook state and actions

const reducer = (state, action) => {

    switch (action.type) {

        case "idle":

            return { status: "idle", data: undefined, error: undefined };

        case "loading":

            return { status: "loading", data: undefined, error: undefined };

        case "success":

            return { status: "success", data: action.payload, error: undefined };

        case "error":

            return { status: "error", data: undefined, error: action.payload };

        default:

            throw new Error("invalid action");

    }

}


function useMemoCompare(next, compare) {

    // Ref for storing previous value

    const previousRef = useRef();

    const previous = previousRef.current;



    // Pass previous and next value to compare function

    // to determine whether to consider them equal.

    const isEqual = compare(previous, next);



    // If not equal update previousRef to next value.

    // We only update if not equal so that this hook continues to return

    // the same old value if compare keeps returning true.

    useEffect(() => {

        if (!isEqual) {

            previousRef.current = next;

        }

    });



    // Finally, if equal then return the previous value

    return isEqual ? previous : next;

}

// Hook

function useFirestoreQuery(query) {

    // Our initial state

    // Start with an "idle" status if query is falsy, as that means hook consumer is

    // waiting on required data before creating the query object.

    // Example: useFirestoreQuery(uid && firestore.collection("profiles").doc(uid))

    const initialState = {

        status: query ? "loading" : "idle",

        data: undefined,

        error: undefined

    };



    // Setup our state and actions

    const [state, dispatch] = useReducer(reducer, initialState);



    // Get cached Firestore query object with useMemoCompare (https://usehooks.com/useMemoCompare)

    // Needed because firestore.collection("profiles").doc(uid) will always being a new object reference

    // causing effect to run -> state change -> rerender -> effect runs -> etc ...

    // This is nicer than requiring hook consumer to always memoize query with useMemo.

    const queryCached = useMemoCompare(query, prevQuery => {

        // Use built-in Firestore isEqual method to determine if "equal"

        return prevQuery && query && query.isEqual(prevQuery);

    });



    useEffect(() => {

        // Return early if query is falsy and reset to "idle" status in case

        // we're coming from "success" or "error" status due to query change.

        if (!queryCached) {

            dispatch({ type: "idle" });

            return;

        }



        dispatch({ type: "loading" });



        // Subscribe to query with onSnapshot

        // Will unsubscribe on cleanup since this returns an unsubscribe function

        return queryCached.onSnapshot(

            response => {

                // Get data for collection or doc

                const data = response.docs

                    ? getCollectionData(response)

                    : getDocData(response);



                dispatch({ type: "success", payload: data });

            },

            error => {

                dispatch({ type: "error", payload: error });

            }

        );



    }, [queryCached]); // Only run effect if queryCached changes



    return state;

}



// Get doc data and merge doc.id

function getDocData(doc) {

    return doc.exists === true ? { id: doc.id, ...doc.data() } : null;

}



// Get array of doc data from collection

function getCollectionData(collection) {

    return collection.docs.map(getDocData);

}


function startGame() {

    // shuffle deck
    let deckArray = shuffle([...Array(getCards().length).keys()]);
    console.log('Shuffled deck: ' + deckArray);
    db.collection('games').doc("1").set({ deck: deckArray }, {merge: true})
        .then(() => {
            startNextRound();
        })
}

function submitSentence(playerId, sentence, points, cards) {

    db.collection('sentences').doc(playerId).set({sentence: sentence, points: points, cards: cards});
}

function updateSentence(playerId, sentence) {
    db.collection('sentences').doc(playerId).set({sentence: sentence}, {merge: true});
}

function endSentenceBuilding() {
    db.collection('games').doc("1").set({state: 'sentenceJudging'}, {merge: true});
}

function setSentenceApproved(playerId, approved) {
    db.collection('sentences').doc(playerId).set({approved: approved}, {merge: true});
}

function endRound(players, sentences) {

    let updatedHands = {};

    // add new points for approved sentences
    sentences.forEach((sentence) => {
        if (sentence.approved) {
            db.collection('players').doc(sentence.id).update({
                points: firebase.firestore.FieldValue.increment(sentence.points)
            });
        }

        // remove used cards from hands
        let playerIndex = players.findIndex(x => x.id === sentence.id);
        console.log(`Removing used cards for player: ${sentence.id} at index: ${playerIndex}`);
        if (playerIndex > -1) {
            let currentHand = [ ...players[playerIndex].hand ];
            console.log(`Player's current hand: ${currentHand}. Sentence cards: ${sentence.cards}`);
            let newHand = currentHand.filter(card => {
                let foundCard = false;
                sentence.cards.forEach((sentenceCard) => {
                    console.log(`Comparing: ${card.id} to ${sentenceCard.id}`);
                    if (card === sentenceCard) {
                        console.log('Match!');
                        foundCard = true;
                    }
                });
                return !foundCard
            });
            console.log(`Setting new hand to: ${newHand}`);
            updatedHands[sentence.id] = newHand;
            db.collection('players').doc(sentence.id).update({
                hand: newHand
            });
        }

        // clear sentences
        db.collection('sentences').doc(sentence.id).delete()
    });

    // update game state
    db.collection('games').doc("1").set({state: 'postRound'}, {merge: true});


}

function startNextRound() {
    // deal cards

    let currentDeck;
    let currentPlayers;
    db.collection('games').doc("1").get({source: 'server'})
        .then((game) => {
            currentDeck = game.data().deck;

            return db.collection('players').get({source: 'server'});
        })
        .then(players => {
            currentPlayers = players.docs.map(player => player.data());
        })
        .then(() => {
            console.log('Dealing from deck: ' + currentDeck + ' to players ' + currentPlayers);
            let promises = [];

            currentPlayers.forEach((player, index) => {
                let cardsNeeded = handSize;
                if (player.hand !== undefined) {
                    cardsNeeded = cardsNeeded - player.hand.length;
                }
                let newCards = currentDeck.splice(0, cardsNeeded);
                let currentHand = player.hand || [];
                currentHand = Array.from([...currentHand, ...newCards]);

                console.log(`Setting player ${player.id} hand to ${currentHand}`);
                promises.push(db.collection('players').doc(player.id).set({hand: currentHand}, {merge: true}));

                if (index === currentPlayers.length - 1) {
                    promises.push(db.collection('games').doc("1").set({deck: currentDeck}, {merge: true}));
                }
            });

            return Promise.all(promises).then(() => {
                // update game state
                return db.collection('games').doc("1").set({state: 'sentenceBuilding'}, {merge: true});
            })
        })
    }


export { db, handSize, getCollectionData, useFirestoreQuery, startGame, submitSentence, updateSentence, endSentenceBuilding, setSentenceApproved, endRound, startNextRound};


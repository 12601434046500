import {db, useFirestoreQuery, setSentenceApproved, endRound} from "./Firebase";
import {AppBar, Button, Card, IconButton, Paper, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import {green, red} from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
    card: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        minHeight: '30pt',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    sentenceText: {
        fontSize: '18pt',
        fontWeight: '400',
        fontFamily: 'Newsreader',
        serif: true
    },
    authorText: {
        fontSize: '18pt',
        fontWeight: '700',
        textAlign: 'left',
        lineHeight: '18pt',
        fontFamily: 'Newsreader',
        serif: true
    },
    votingPane: {
        display: 'flex',
        flexDirection: 'column'
    },
    thumbsUp: {
        color: green[500]
    },
    thumbsDown: {
        color: red[500]
    },
    unselected: {
        color: "lightgray"
    },
    topBar: {
        height: '30pt'
    },
    paper: {
        padding: '30pt'
    }
}));

function SentenceJudging({}) {

    const classes = useStyles();

    const sentences = useFirestoreQuery(
        db.collection("sentences")
    );

    const players = useFirestoreQuery(
        db.collection("players")
    );

    const allSentencesDecided = () => {
        if (sentences.data === undefined) return false;

        let decided = true;
        sentences.data.forEach( (sentence) => {
            if (sentence.approved === undefined) decided = false;
        });
        return decided;
    }

    return (
        <Paper className={classes.paper}>
            <AppBar className={classes.topBar}> Sentence Judging!</AppBar>

            {(sentences.data !== undefined && sentences.data.map( (sentence) => {
                return ( <Card className={classes.card} variant="outlined" elevation={2}>
                    <div>
                        <Typography className={classes.authorText}>
                            {sentence.id}  -  {sentence.points} points
                        </Typography>
                            <br/>
                        <Typography className={classes.sentenceText}>
                            {sentence.sentence}
                        </Typography>
                    </div>
                    <div className={classes.votingPane}>
                        <IconButton onClick={ () => setSentenceApproved(sentence.id, true)}><ThumbUpIcon className={ sentence.approved ? classes.thumbsUp : classes.disabled}/></IconButton>
                        <IconButton onClick={ () => setSentenceApproved(sentence.id, false)}><ThumbDownIcon className={ sentence.approved === false ? classes.thumbsDown : classes.disabled}/></IconButton>

                    </div>
                </Card>);
            }))}
            {players.data !== undefined && allSentencesDecided() && (<Button variant="contained" color="primary" onClick={() => endRound(players.data, sentences.data)}>End Round</Button> )}
        </Paper>


        )
}

export default SentenceJudging;